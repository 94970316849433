import sanityClient from '@sanity/client'
import imageUrlBuilder from '@sanity/image-url'

export const client = sanityClient({
    projectId: "qxqu8tba",
    dataset: 'production',
    apiVersion: "2023-06-18",
    useCdn: true,
    token: 'skZfAV8UVDdpWNwRMhKe4ZQc9EuudrzDRfbBEQdzd467bqKWfAcsOE2Scp30AQpdJWBojRyT9dleFIHPcaTvjz5s94hoj3uNaM2ec4odEbf6kjhvR6XFUPI1092RXd88EU4ipmxSwosbTLFOycwu61zfD2hh5XGbxTnKEmaWKYk52jHEB3UZ',
})

const builder = imageUrlBuilder(client)

export const urlFor = (source) => builder.image(source)