import React from 'react'
import { BsTwitter, BsGithub } from 'react-icons/bs'
import { FaLinkedinIn } from 'react-icons/fa'

const SocialMedia = () => {
    return (
        <div className='app__social'>
            <div 
                onClick={() => window.open('https://twitter.com/yr1404_', '_blank')}
            >
                <BsTwitter />
            </div>
            <div 
                onClick={() => window.open('https://www.linkedin.com/in/yr1404', '_blank')}
            >
                <FaLinkedinIn />
            </div>
            <div
                onClick={() => window.open('https://github.com/yr1404', '_blank')}
            >
                <BsGithub />
            </div>
        </div>
    )
}

export default SocialMedia